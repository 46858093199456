<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <b-card>
              <b-card-body class="p-0">
                <b-card-title>
                  Petition Signatures for WGAW Board Candidacy.
                </b-card-title>
                <div class="guide-tip">
                  <b-row align-v="center">
                    <b-col cols="3" lg="1" class="icon">
                      <font-awesome-icon :icon="['far', 'file-alt']" />
                    </b-col>
                    <b-col cols="12" lg="8" order="3" order-lg="2" class="text">
                      <div class="mb-2">
                        Please note that
                        <!-- <span class="font-weight-bold">25</span> eligible
                        signatures are required for officer candidates and -->
                        <span class="font-weight-bold">15</span> eligible
                        signatures are required for Board candidates.
                      </div>
                      <!-- <div v-if="signaturesReached">
                        You have received the required number of signatures.
                        Please click
                        <span class="font-weight-bold">Submit Signatures</span>
                        below to submit your signatures to WGAW for
                        verification. WGAW will contact you directly with the
                        results of the review
                      </div>
                      <div v-else-if="isSubmitted">
                        Now that you have submitted signatures you can continue
                        to collect and submit signatures at your discretion
                        until you are approved as an official candidate or the
                        July 23 (12:00 p.m. PDT) deadline passes.
                      </div> 
                      <div v-else>
                        Once you have received the required number of signatures
                        a
                        <span class="font-weight-bold">Submit Signatures</span>
                        button will appear allowing you to submit your
                        signatures to WGAW for verification. WGAW will contact
                        you directly with the results of the review.
                      </div>-->
                      <div>
                        Once you have received the required 15 signatures your
                        account will automatically convert from a petition
                        candidate to a verified candidate. An email will be sent
                        to WGAW on your behalf reporting that you have fulfilled
                        the petition requirement for Board candidacy.
                      </div>
                    </b-col>
                    <b-col
                      cols="9"
                      lg="3"
                      order="2"
                      order-lg="3"
                      class="button"
                    >
                      <b-button
                        variant="outline-secondary"
                        size="sm"
                        v-b-modal.modal-theme-guide
                      >
                        View Example
                        <font-awesome-icon :icon="['far', 'window-maximize']" />
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-if="signaturesReached"
                  variant="primary"
                  class="mt-3 mb-5 float-right"
                  @click="submitAll"
                  :disabled="saving || isSubmitted"
                  ><b-spinner
                    small
                    style="position: relative; top: -3px"
                    v-if="saving"
                  ></b-spinner>
                  {{ submitText }}
                  <font-awesome-icon :icon="['fas', 'share']" />
                </b-button>

                <!-- <b-button
                  v-if="signaturesReached"
                  variant="outline-secondary"
                  class="mt-3 mb-5"
                  @click="onExportAll"
                  :disabled="exporting"
                  ><b-spinner
                    small
                    style="position: relative; top: -3px"
                    v-if="exporting"
                  ></b-spinner>
                  Export All
                  <font-awesome-icon :icon="['fas', 'file-excel']" />
                </b-button> -->
                <b-alert :show="!!errorMessage" variant="danger">{{
                  errorMessage
                }}</b-alert>

                <b-table
                  striped
                  responsive
                  ref="table"
                  :fields="petition.fields"
                  :items="petitionTableItems"
                >
                </b-table>
              </b-card-body>
            </b-card>
            <AdminFooterButton
              @on-back="onBack"
              :hideSkipButton="true"
              :hidePreviewButton="true"
              :hideSaveButton="true"
              :hideDashboardButton="false"
            />
          </b-col>
        </b-row>
      </b-container>
    </main>
    <ModalThemeGuide
      theme-id="petition"
      :template-id="candidateModel.profileTheme"
    />
  </div>
</template>

<script>
import CandidateMixin from "@/mixins/candidate.mixin";
import Repository from "@/repositories/RepositoryFactory";

const ExportRepository = Repository.get("ExportRepository");

export default {
  name: "ManagePetition",
  mixins: [CandidateMixin],
  data() {
    return {
      loading: false,
      errorMessage: null,
      export_fields: {
        "First Name": "firstName",
        "Last Name": "lastName",
        Email: "email",
        Status: "status",
      },
      petition: {
        fields: [
          {
            label: "#",
            key: "tableOrder",
          },
          {
            label: "First Name",
            key: "firstName",
          },
          {
            label: "Last Name",
            key: "lastName",
          },
          {
            label: "Email",
            key: "email",
          },
        ],
        items: [],
      },
      signatures: 0,
      submitText: "Submit All",
      isSubmitted: false,
      saving: false,
      exporting: false,
    };
  },
  computed: {
    petitionTableItems() {
      return this.candidateModel.petitions.map(function (item, index) {
        return {
          ...item,
          tableOrder: index + 1,
        };
      });
    },
    signaturesReached() {
      return (
        this.candidateModel.petitions.length >=
        this.candidateModel.requiredSignatures
      );
    },
  },
  methods: {
    async submitAll() {
      try {
        this.saving = true;
        this.submitText = "Processing";
        await this.$store.dispatch(
          "candidates/submitPetitionsToAdmin",
          this.$route.params.id
        );
        this.submitText = "Submitted";
        this.isSubmitted = true;
        this.saving = false;
      } catch (e) {
        console.log(e);
      }
    },
    async onExportAll() {
      try {
        this.exporting = true;
        setTimeout(async () => {
          await ExportRepository.ExportPetitions(this.$route.params.id);
          this.exporting = false;
        }, 500);
      } catch (e) {
        console.log(e);
      }
    },
    onPreview() {},
    onBack() {
      if (this.hasSubmittedItems) this.$router.push("approved-endorsements");
      else if (this.hasUnsubmittedItems)
        this.$router.push("unapproved-endorsements");
      else this.$router.push("add-endorsement");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";
</style>
